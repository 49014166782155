
































































import { Component, Vue } from "vue-property-decorator";
import Form from "@/components/Form.vue";

@Component({
  components: { Form }
})
export default class Home extends Vue {
  openNewsletter() {
    setTimeout(
      () =>
        (this.$refs.newsletter as HTMLElement).scrollIntoView({
          block: "start",
          behavior: "smooth"
        }),
      100
    );
    (this.$refs.newsletter as HTMLElement).style.visibility = "visible";
    (this.$refs.newsletter as HTMLElement).style.maxHeight = "900px";
    (this.$refs.welcome as HTMLElement).style.maxHeight = "0px";
  }
  mounted() {
    if (localStorage.getItem("registered") === "1") {
      window.location.replace("./app");
    }
  }
}
