














import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Header extends Vue {
  private pro = window.location.href.indexOf("/pro") > 0;
}
