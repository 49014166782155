









import Header from "@/partials/Header.vue";
import Footer from "@/partials/Footer.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import { Component, Vue } from "vue-property-decorator";
import { bus } from "./main";

@Component({
  components: { Footer, Header, About, Contact }
})
export default class MainSuccess extends Vue {
  private showMain = true;
  private about = false;
  private contact = false;
  created() {
    bus.$on("showTemplate", (templateName: string) => {
      this.showMain = templateName === "main" ? true : false;
      this.about = templateName === "about" ? true : false;
      this.contact = templateName === "contact" ? true : false;
    });
  }
}
