





















































import { Component, Vue } from "vue-property-decorator";
import { bus } from "@/main";

@Component({
  components: {}
})
export default class Footer extends Vue {
  showTemplate(templateName: string) {
    bus.$emit("showTemplate", templateName);
  }
}
