















import { Component, Vue } from "vue-property-decorator";
import { bus } from "@/main";
@Component({
  components: {}
})
export default class PageBreadCrumb extends Vue {
  hideContent() {
    bus.$emit("showTemplate", "main");
  }
}
